import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { Metadata, SampleRequestForm } from '../components'

import './common.sass'

const VerticallyCentered: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props}) => <div {...props} style={{
    display: 'flex',
    flexFlow: 'column nowrap'
}}>
    <div style={{flexGrow: 2}} />
    <div>{children}</div>
    <div style={{flexGrow: 3}} />
</div>

const Header = ({ size, children }) => <p style={{
    fontFamily: 'Playfair Display SC',
    fontSize: size,
    lineHeight: 1,
    marginBottom: '1.6rem'
}}>{children}</p>

const IndexPage = () => <>
    <Metadata />
    <main className="mt-3-md mb-3-md">
        <div className="container">
            <div className="row section">
                <VerticallyCentered className="col-md-6 order-md-2">
                    <Header size="3rem">Let them see.</Header>
                    <p>Sol&nbsp;tags connect&nbsp;you to&nbsp;ideas and&nbsp;craftsmanship that make&nbsp;up your&nbsp;instrument.</p>
                    <p>They can&nbsp;be easily scanned with most smartphones without an&nbsp;app.</p>
                    <p>Ask your luthier to&nbsp;fit one to&nbsp;your order.</p>
                </VerticallyCentered>
                <div className="col-md-6 mt-3" style={{display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}}>
                    <StaticImage
                        className="mockup"
                        src="../images/iphone_item.png"
                        width={350}
                        alt="Phone mockup showing an instrument view"
                        loading="eager"
                        placeholder="none"
                    />
                </div>
            </div>
            <div className="row section">
                <VerticallyCentered className="col-md-6">
                    <Header size="2rem">Every detail counts.</Header>
                    <p>Document the build.</p>
                    <p>Showcase the finished instrument.</p>
                    <p>There's no limit to the number of photos you can upload.</p>
                    <p style={{marginTop: '2rem'}}>
                        <a href="https://web.soltag.info/item/32590bc7-5ed7-4324-a777-951bd937ab8d" target="_blank" style={{color: 'blue'}}>
                        Take a closer look
                        &nbsp;
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{height: '1em'}} />
                        </a>
                    </p>
                </VerticallyCentered>
                <div className="col-md-6 mt-3">
                    <StaticImage
                        className="mockup"
                        src="../images/ipad_gallery_right.png"
                        width={600}
                        alt="Phone mockup showing an instrument view"
                        placeholder="none"
                    />
                </div>
            </div>
            <div className="row section">
                <VerticallyCentered className="col-md-8">
                    <Header size="2rem">Stop worrying about counterfeits.</Header>
                    <p>Sol tags are designed with security in mind and can digitally prove their authenticity.</p>
                </VerticallyCentered>
                <div className="col-md-4 mt-3" style={{display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}}>
                    <StaticImage
                        src="../images/padlock.svg"
                        alt="Padlock"
                        placeholder="none"
                    />
                </div>
            </div>
            <div className="row section">
                <VerticallyCentered className="col-xl-6">
                    <Header size="2rem">Try it for free.</Header>
                    <p>We offer Sol tags at a fixed price of £10 a piece excl. VAT and shipping.</p>
                    <p>There are no recurring fees and no registration costs.</p>
                    <p>There is no minimum order quantity.</p>
                    <p>Discounts are available for orders above 50 pcs.</p>
                    <p style={{marginTop: '2rem'}}>
                        Already have an account?
                        &emsp;
                        <a href="https://atelier.soltag.info/" target="_blank" style={{color: 'blue'}}>
                            Sign in.
                        </a>
                    </p>
                </VerticallyCentered>
                <div className="col-xl-6">
                    <SampleRequestForm />
                </div>
            </div>
            <div className="section">
                <Header size="3rem">Get in touch.</Header>
                <p>For all queries, please e-mail <a href="mailto:contact@soltag.info" style={{color: 'blue'}}>contact@soltag.info</a>.</p>
            </div>
        </div>
        <div style={{marginTop: '2rem', marginBottom: '2rem', textAlign: 'center'}}>
            <small>
                <p>
                    Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()}&nbsp;Celerity&nbsp;Audio&nbsp;Ltd
                </p>
                <p>
                    Celerity&nbsp;Audio&nbsp;Ltd is a private&nbsp;limited&nbsp;company registered in&nbsp;England. Company&nbsp;no.:&nbsp;11146556. VAT&nbsp;no.:&nbsp;GB&nbsp;295&nbsp;1511&nbsp;00.
                    <br />
                    Registered&nbsp;address: DNS&nbsp;House, 382&nbsp;Kenton&nbsp;Road, Harrow, HA3&nbsp;8DP, Middlesex, United&nbsp;Kingdom.
                </p>
            </small>
        </div>
    </main>
</>

export default IndexPage
