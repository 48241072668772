import React, { useState } from 'react'

import { useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import ReCAPTCHA from 'react-google-recaptcha'

const CAPTCHA_SITEKEY = '6Lf3UHYUAAAAALQeb3RJinavWrvatKKAOU_vL3g1'
const API_URL = 'https://api.sol.celerity.audio'

export const SampleRequestForm: React.FC = () => {
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)

    type Data = {
        captchaResponse: string,
        fullName: string,
        email: string,
        company: string,
        phoneNumber: string,
        address: string
    }
    const { register, handleSubmit, setValue } = useForm<Data>()

    const onSubmit = (data: Data) => {
        const request = new Request(`${API_URL}/samplePackRequest`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        const t = async () => {
            try {
                setDisableSubmit(true)
                const response = await fetch(request)
                if (response.ok) { setSuccess(true) }
                else { setErrorMessage('Something went wrong. Please try again.') } // TODO bugsnag
            } catch (e) {
                if (e instanceof TypeError) { setErrorMessage('Please check your internet connection and try again.') }
                else { setErrorMessage('Something went wrong. Please try again later.') } // TODO bugsnag
            } finally {
                setDisableSubmit(false)
            }
        }
        t()
    }

    if (success) return <p>Thank you for your interest in Sol. We'll be in touch shortly.</p>

    return <form onSubmit={handleSubmit(onSubmit)}>
        <p style={{fontSize: '1.2em'}}>Please provide your contact details.<br />We won't share them with anyone.</p>
        <div className="mb-3">
            <label className="form-label" htmlFor="fullName">Name (required)</label>
            <input className="form-control" id="fullName" {...register('fullName')} required />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="email">E-mail address (required)</label>
            <input className="form-control" id="email" type="email" {...register('email')} required />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="company">Brand</label>
            <input className="form-control" id="company" {...register('company')} />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="phoneNumber">Phone number</label>
            <input className="form-control" id="phoneNumber" {...register('phoneNumber')} />
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="address">Postal address</label>
            <TextareaAutosize className="form-control" id="address" {...register('address')} />
        </div>
        <div className="mb-3">
            <ReCAPTCHA
                sitekey={CAPTCHA_SITEKEY}
                onChange={(value: string) => {
                    setValue('captchaResponse', value)
                    setDisableSubmit(value === null)
                }}
            />
        </div>
        {(errorMessage !== null) && <div className="alert alert-warning mb-3" role="alert">{errorMessage}</div>}
        <input className="btn btn-primary" type="submit" disabled={disableSubmit} />
    </form>
}
